


























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import draggable from "vuedraggable";
import SubArrayForm from "@/components/SubArrayForm.vue";
import { Guid } from "guid-typescript";
import DatePicker from "@/components/DatePicker.vue";
import {
  Questionnaire,
  QuestionnaireSubmission
} from "@/data/models/Questionnaires";
import EntitySelect from "@/components/EntitySelect.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import FileInput from "@/components/FileInput.vue";
import { toArray, toMap } from "@/lib/answers";
import { saveAs } from "file-saver";

@Component({
  components: {
    FormContainer,
    Loader,
    draggable,
    DatePicker,
    SubArrayForm,
    EntitySelect,
    DynamicForm,
    FileInput
  }
})
export default class AlwaysAvailableQuestionnaireSubmissionForm extends Vue {
  @Prop()
  public selectedQuestionnaire!: any;

  @Prop()
  public prevSubmission!: any;

  @Prop()
  public lockBeneficiary!: any;

  private users: any[] = [];
  private submitting = false;
  private questionnaire: any = null;
  private answers: any[] = [];
  private isNew = false;
  private item: any = null; // Project | null = null;
  private formTitle = "";

  private householdMemberODataArgs = {
    columnFilters: [
      {
        column: "IsDirectBeneficiary",
        value: "true",
        op: "Equals"
      }
    ],
    top: 100
  };
  private async submit() {
    const itemToSubmit = JSON.parse(JSON.stringify(this.item));
    delete itemToSubmit.prescreening;
    delete itemToSubmit.beneficiary;

    const answersArr = this.answers;
    itemToSubmit.answers = answersArr;
    itemToSubmit.uploadedFile = this.item.uploadedFile;
    if (this.isNew) {
      await this.$service.crud.questionnaireSubmissions.addAsync([
        itemToSubmit
      ]);
    } else {
      await this.$service.crud.questionnaireSubmissions.saveAsync(itemToSubmit);
    }
  }
  async mounted() {
    this.users = (await this.$service.providers.users.fetchItemsAsync()).items;
    this.questionnaire = this.selectedQuestionnaire;
    this.formTitle = this.questionnaire.name;
    if (this.prevSubmission == null) {
      this.isNew = true;
      this.item = {
        id: Guid.create().toString(),
        submissionDate: new Date(),
        questionnaireId: this.questionnaire.id,
        userId: this.users[0].id,
        beneficiaryId: "",
        beneficiary: null,
        prescreeningId: "",
        presreening: null,
        uploadedFile: null
      };
    } else {
      this.isNew = false;
      this.answers = this.prevSubmission.answers;
      this.item = JSON.parse(JSON.stringify(this.prevSubmission));
    }
    if (this.lockBeneficiary != null) {
      this.item.beneficiary = this.lockBeneficiary;
      this.item.beneficiaryId = this.lockBeneficiary.id;
    }
  }
  async download() {
    saveAs(
      await this.$service.downloadFile(this.questionnaire.downloadedFile.name),
      this.questionnaire.downloadedFile.name
    );
  }
}

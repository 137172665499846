import printer from "@/views/animatedPrinter";

export function bufferToBinaryString(arrayBuffer: any) {
  return String.fromCharCode(...new Uint8Array(arrayBuffer));
}

export default async function openPdf(blobPromise: Promise<Blob>) {
  const win = window.open("");
  if (!win) {
    return;
  }
  const html = printer;
  win.document.write(html);
  win.document.close();
  try {
    const blob = await blobPromise;
    const base64 = btoa(bufferToBinaryString(await blob.arrayBuffer()));
    const embed = `<embed width="100%" height="100%" src="data:application/pdf;base64,${base64}" type="application/pdf" />`;
    win.document.body.innerHTML = embed;
  } catch (e) {
    win.close();
    throw e;
  }
}









































import { Component, Prop, Vue } from "vue-property-decorator";
import SessionForm from "@/components/forms/SessionForm.vue";
import ActivityRecordForm2 from "@/components/forms/ActivityRecordForm2.vue";
import DataProvider from "@/lib/DataProvider";
import { Project } from "@/data/models/Projects";

@Component({
  components: { SessionForm, ActivityRecordForm2 }
})
export default class ActivitySessionsHistory extends Vue {
  @Prop()
  public items!: any[];

  @Prop()
  public projectsProvider!: DataProvider<Project>;

  private showSessionForm = false;
  private showActivityForm = false;
  addSession() {
    this.showSessionForm = true;
  }

  addActivity() {
    this.showActivityForm = true;
  }
}

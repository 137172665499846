export default `<html><head>
<title>Export Document</title>
<script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/1.20.3/TweenMax.min.js"></script>
<script>
window.onload = function(){
const tl = new TimelineMax();
tl.set('svg', { visibility: 'visible' }).
fromTo('svg', 0.5, { scale: 0.8, opacity: 0, rotation: -45 }, { scale: 1, opacity: 1, rotation: 0, ease: Back.easeInOut }).
addLabel('start', '+=0.5').
to('.top_paper', 8, { yPercent: 100, ease: Power0.easeNone }, 'start').
fromTo('.bottom_paper', 8, { yPercent: -70 }, { yPercent: 0, ease: Power0.easeNone }, 'start');
}
</script>
<style>
svg {
max-width: 100%;
height: auto;
padding: 1em;
display: block;
visibility: hidden;
filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.25));
}
</style></head>
<body style="margin:0!important; background: #f8f8f8; display:flex; justify-content: center; overflow:hidden">
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="548.7px" height="548.7px" viewBox="0 0 548.7 548.7">
<style>
.st0{fill:#85d1a3}.st1{fill:#fff}.st2{fill:#e6e6e6}.st3{fill:#b3b3b3}.st4{fill:#a0a0a0}.st5{clip-path:url(#top_mask)}.st6{clip-path:url(#bottom_mask)}
</style>
<g>
<circle class="st0" cx="274.3" cy="274.3" r="274.3"/>
<g>
  <g>
    <g>
      <path class="st1" d="M134.7 404.2L172 327.7 376.7 327.7 414 404.2z"/>
      <path class="st2" d="M134.7 217.2H414V404.2H134.7z"/>
      <path class="st3" d="M134.7 217.2L172.3 190.7 376.4 190.7 414 217.2z"/>
    </g>
    <g>
      <path class="st1" d="M187.7 432.2L210.8 384.7 337.8 384.7 361 432.2z"/>
      <g>
        <path class="st1" d="M211.1 299.9H337.6V384.5H211.1z"/>
        <path d="M337.4,300.2v84.1H211.3v-84.1H337.4 M337.8,299.7h-127v85h127V299.7L337.8,299.7z"/>
      </g>
      <path class="st4" d="M187.7 316.1H361.1V432.20000000000005H187.7z"/>
      <path class="st3" d="M187.7 316.1L211 299.7 337.7 299.7 361 316.1z"/>
    </g>
    <g>
      <defs>
        <path id="top_x5F_mask_1_" d="M134.7 16.9H414V203.9H134.7z"/>
      </defs>
      <clipPath id="top_mask">
        <use xlink:href="#top_x5F_mask_1_" overflow="visible"/>
      </clipPath>
      <g class="st5">
        <path class="st1 top_paper" d="M338.9,259.2c-45.8-1.3-91.5-1.3-137.2,0c-1.9-59-3.8-117.9-5.7-176.9c49.6-1.4,99.1-1.4,148.7,0 C342.7,141.2,340.8,200.2,338.9,259.2z"/>
      </g>
    </g>
  </g>
  <g>
    <defs>
      <path id="bottom_x5F_mask_1_" d="M203.9 402.3H361V510.4H203.9z"/>
    </defs>
    <clipPath id="bottom_mask">
      <use xlink:href="#bottom_x5F_mask_1_" overflow="visible"/>
    </clipPath>
    <g class="st6">
      <path class="st1 bottom_paper" d="M346.8,492.8c-46.8,2.6-93.4,2.6-140.1,0c3-45.9,6-91.8,8.9-137.7c40.8,2.2,81.4,2.2,122.3,0 C340.8,401,343.8,446.9,346.8,492.8z"/>
    </g>
  </g>
</g>
</g>
</svg>

</body></html>`;








































import ICrudClient from "@/lib/ICrudClient";
import { Project } from "@/data/models/Projects";
import { Session } from "@/data/models/SessionActivities";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";

@Component({
  components: {
    FormContainer,
    Loader
  }
})
export default class SessionForm extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public provider!: DataProvider<Session>;

  @Prop()
  public projectsProvider!: DataProvider<Project>;

  @Prop()
  public crud!: ICrudClient<Session>;

  private item: any = null;

  private projects: any[] = [];

  async mounted() {
    if (!this.id) {
      this.item = {
        name: ""
      };
    }
    await this.onProjectProviderChanged();
  }

  @Watch("projectsProvider")
  async onProjectProviderChanged() {
    if (this.projectsProvider) {
      this.projects = (await this.projectsProvider.fetchItemsAsync()).items;
    }
  }
}






























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import { HouseholdMember } from "@/data/models/HouseholdMember";
import HouseholdMemberBasicData from "@/components/forms/HouseholdMemberBasicData.vue";
import AlwaysAvailableQuestionnaireSubmissionForm from "@/components/forms/AlwaysAvailableQuestionnaireSubmissionForm.vue";
import { Guid } from "guid-typescript";
import openPdf from "@/lib/openPdf";

@Component({
  components: {
    FormContainer,
    Loader,
    AlwaysAvailableQuestionnaireSubmissionForm,
    HouseholdMemberBasicData
  },
})
export default class HouseholdMemberBasicDataForm extends Vue {
  public item: HouseholdMember | null = null;

  @Prop()
  public id!: string;

  private questionnaires: any = [];

  private showQuestionnaireDialog = false;
  private selectedQuestionnaire: any = null;
  private previousSubmission: any = null;

  private async showQuestionnaire(q: any) {
    const quest = await this.$service.providers.questionnaires.fetchItemAsync(
      q.id
    );
    this.selectedQuestionnaire = quest;
    this.previousSubmission = null;
    this.showQuestionnaireDialog = true;
  }

  private reset() {
    this.showQuestionnaireDialog = false;
    this.selectedQuestionnaire = null;
    this.previousSubmission = null;
  }

  async mounted() {
    this.questionnaires = (
      await this.$service.providers.questionnaires.fetchItemsAsync({
        columnFilters: [
          {
            column: "QuestionnaireType",
            value: `'AlwaysAvailable'`,
            op: "Equals",
          },
        ],
      })
    ).items;
    if (this.id === "new") {
      this.item = {
        id: Guid.create().toString(),
        firstName: "",
        lastName: "",
        middleName: "",
        motherName: "",
        dateOfBirth: null,
        gender: null,
        nationality: null,
        legalStatus: null,
        socialNumber: "",
        phoneNumber: "",
        otherNumber: "",
        identityNo: "",
        passportNo: "",
        amkaNo: "",
        afmNo: "",
        ric: "",
        addressOfResidence: "",
        numberOfContainer: "",
        otherContactDetails: "",
        email: "",
        insuranceOrPermitDate: null,
        expirationDate: null,
        householdHead: false,
        housingType: null,
        HouseholdMemberRole: null,
        isDirectBeneficiery: false,
        household: null,
        caseManagers: [],
        files: [],
        legalFiles: [],
        history: [],
      };
    }
  }
  async download() {
    openPdf(this.$service.downloadHouseholdMember(this.id));
  }
}



































































import { Component, Vue, Prop } from "vue-property-decorator";
import ModelTable from "@/components/ModelTable.vue";
import ActivitySessionsHistory from "@/components/ActivitySessionsHistory.vue";
import HouseholdMemberBasicDataForm from "@/components/forms/HouseholdMemberBasicDataForm.vue";
import EntitySelect from "@/components/EntitySelect.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import Loader from "@/components/Loader.vue";

@Component({
  components: {
    ModelTable,
    ActivitySessionsHistory,
    HouseholdMemberBasicDataForm,
    DynamicForm,
    Loader,
    EntitySelect
  }
})
export default class OpenCall extends Vue {
  private tab = "details";
  @Prop()
  public id!: string;

  async mounted() {
    //
  }
}






























































































































































































































































































































































































































































































































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import { HouseholdMember } from "@/data/models/HouseholdMember";
import SubArrayForm from "@/components/SubArrayForm.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import { User } from "@/data/models/Users";
import { HouseholdMemberRole } from "@/data/models/HouseholdMemberRoles";
import { HousingType } from "@/data/models/HousingTypes";
import { Gender } from "@/data/models/Genders";
import { Nationality } from "@/data/models/Nationalities";
import { LegalStatus } from "@/data/models/LegalStatus";
import { Guid } from "guid-typescript";
import { HouseholdType } from "@/data/models/HouseholdType";
import EntitySelect from "@/components/EntitySelect.vue";
import DatePicker from "@/components/DatePicker.vue";
import EnumForm from "@/components/forms/EnumForm.vue";

import FileInput from "@/components/FileInput.vue";

@Component({
  components: {
    FormContainer,
    Loader,
    SubArrayForm,
    DynamicForm,
    EntitySelect,
    DatePicker,
    FileInput
  }
})
export default class HouseholdMemberBasicData extends Vue {
  @Prop()
  public item!: any;

  @Prop()
  public questionnaire!: any;

  private enumForm = EnumForm;
  private legalFiles: [] = [];
  private householdMemberStatuses = ["Open", "Closed"];
  @Watch("household")
  private radiogroup = "lack";
  private additionalInformation: any = null;

  @Watch("legalFiles", { immediate: false })
  onFilesChanged() {
    if (!this.item) {
      return;
    }
    this.item.legalFiles = this.legalFiles.map((f: any) => f.file);
  }
  validate(i: any) {
    return i && !!i.file.name;
  }

  @Watch("item", { deep: false, immediate: true })
  async onItemChanged(newVal: any, oldVal: any) {
    if (!this.item) {
      return;
    }
    this.legalFiles = this.item
      ? this.item.legalFiles.map((f: any) => ({ file: f }))
      : [];

    await this.initialize();
  }

  async initialize() {
    if (
      !this.item.additionalInformation ||
      !this.item.additionalInformation.questionnaireId
    ) {
      const additional = await this.$service.providers.questionnaires.fetchItemsAsync(
        {
          columnFilters: [
            {
              column: "isAdditionalBasicInformation",
              op: "Equals",
              value: "true"
            }
          ]
        }
      );
      if (additional.items.length > 0) {
        if (!this.item.additionalInformation) {
          this.item.additionalInformation = {
            answers: [],
            questionnaire: null,
            questionnaireId: null
          };
        }
        this.item.additionalInformation.questionnaire = await this.$service.providers.questionnaires.fetchItemAsync(
          additional.items[0].id
        );
        this.item.additionalInformation.questionnaireId = this.item.additionalInformation.questionnaire.id;
      }
    } else {
      this.item.additionalInformation.questionnaire = await this.$service.providers.questionnaires.fetchItemAsync(
        this.item.additionalInformation.questionnaireId
      );

      this.item.additionalInformation.questionnaireId = this.item.additionalInformation.questionnaire.id;
    }
  }

  async mounted() {
    this.item.insuranceOrPermitDate = this.item.insuranceOrPermitDate ?? " ";
    this.item.expirationDate = this.item.expirationDate ?? " ";
  }
}

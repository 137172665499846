







































import ICrudClient from "@/lib/ICrudClient";
import { ActivityRecord } from "@/data/models/ActivityRecord";
import { Project } from "@/data/models/Projects";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import { Activity } from "@/data/models/Activity";

@Component({
  components: {
    FormContainer,
    Loader
  }
})
export default class ActivityRecordForm2 extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public provider!: DataProvider<ActivityRecord>;

  @Prop()
  public projectsProvider!: DataProvider<Project>;

  @Prop()
  public activitiesProvider!: DataProvider<Activity>;

  @Prop()
  public crud!: ICrudClient<ActivityRecord>;

  private item: any = null;

  private projects: any[] = [];

  private activities: any[] = [];

  async mounted() {
    if (!this.id) {
      this.item = {
        name: ""
      };
    }
    await this.onProjectProviderChanged();
  }

  @Watch("projectsProvider")
  async onProjectProviderChanged() {
    if (this.projectsProvider) {
      this.projects = (await this.projectsProvider.fetchItemsAsync()).items;
    }
  }

  @Watch("activitiesProvider")
  async onActivitiesProviderChanged() {
    if (this.projectsProvider) {
      this.activities = (await this.activitiesProvider.fetchItemsAsync()).items;
    }
  }
}
